import Typography from '@mui/material/Typography';
import LuminaButton from 'core/components/Button';
import { useRouter } from 'next/router';

export default function Home(): JSX.Element {
    const router = useRouter();

    const onBack = (): void => {
        router.push('/');
    };
    return (
        <div className="w-screen h-screen flex flex-col justify-center items-center p-6 text-center">
            <Typography variant="24-medium">
                Mohon maaf, halaman yang anda cari tidak ditemukan
            </Typography>
            <div className="my-4">
                <LuminaButton onClick={onBack}>Kembali</LuminaButton>
            </div>
        </div>
    );
}
